<script setup lang="ts">
import type { BillingScopeResponse } from '@azure/services/billingScope';
import type { CreateServicePrincipalResponse } from '@azure/services/servicePrincipal';
import type { Tenant } from '@azure/services/subscriptions';

import '@ag-grid-community/styles/ag-grid.css';
import '@ag-grid-community/styles/ag-theme-quartz.css';
import _ from 'lodash';
import { onMounted, ref } from 'vue';

import { getOnboardingDiscoveryStatus, createOnboardingDiscoveryRequest } from '@azure/services/billingScope';
import { get, validateTenantAccess } from '@azure/services/servicePrincipal';
import { getSubscriptionsGroupedByTenant } from '@azure/services/subscriptions';
import { useAzureStore } from '@azure/state';

import AzureTenantFilterList from '@azure/components/tenants/AzureTenantFilterList.vue';
import BoxMessage from '@shared/design/BoxMessage.vue';
import SpinnerLogo from '@shared/design/spinners/SpinnerLogo.vue';

const azureState = useAzureStore();
const loading = ref(true);
const gatheringDetails = ref(true);
const tenantActionNeeded = ref(false);
const tenants = ref<Tenant[]>([]);
const billingScope = ref<BillingScopeResponse | undefined>(undefined);
const servicePrincipal = ref<CreateServicePrincipalResponse | undefined>(undefined);
const interval = ref();
const validating = ref(false);

const props = defineProps<{
  prev: () => void;
  next: () => void;
}>();

onMounted(async () => {
  loading.value = true;
  const billingScopeId = azureState.getActiveBillingScopeId();
  if (billingScopeId) {
    // get the billing scope first
    billingScope.value = await azureState.getBillingScopeById(billingScopeId);

    // if the billingscope has an active discovery status, we need to poll that process
    if (billingScope.value?.onboarding_discovery_status_id) {
      await pollAzureDiscovery(billingScopeId, billingScope.value.onboarding_discovery_status_id);
      loading.value = false;
      return;
    }

    // start with getting the billing scopes subscriptions grouped by tenant
    tenants.value = await getSubscriptionsGroupedByTenant(billingScopeId);

    // if we already have tenants, we can skip the discovery process
    if (tenants.value.length > 0) {
      gatheringDetails.value = false;
      tenantActionNeeded.value = _.some(tenants.value, { validated: false });
      if (!tenantActionNeeded.value) {
        props.next();
      }
      loading.value = false;
      return;
    }

    // if we don't have any tenants, we need to start the discovery process
    await discoverAzureSubscriptions(billingScopeId);

    loading.value = false;
  }

  // this is a fall through, we didn't have a billing scope
  loading.value = false;
});

const discoverAzureSubscriptions = async (billingScopeId: string) => {
  // begin the subscription capture for this billing scope
  var discoveryRequestResult = await createOnboardingDiscoveryRequest(billingScopeId);

  // poll the discovey status until it is complete
  await pollAzureDiscovery(billingScopeId, discoveryRequestResult.capture_id);
};

const pollAzureDiscovery = async (billingScopeId: string, captureId: string) => {
  interval.value = setInterval(async () => {
    const result = await getOnboardingDiscoveryStatus(billingScopeId, captureId);
    if (result?.status === 'Success') {
      // if the discovery process is complete, we need to clear the interval to stop polling
      clearInterval(interval.value);

      // if the billing scope has a service principal, we need to get the details for it
      if (billingScope.value?.service_principal_id) {
        servicePrincipal.value = await get(billingScope.value?.service_principal_id);
      }

      // get the billing scope subscriptions grouped by tenant
      tenants.value = await getSubscriptionsGroupedByTenant(billingScopeId);

      // finished discovery, need to show tenants
      gatheringDetails.value = false;

      // check if there are any tenants that need validation
      tenantActionNeeded.value = _.some(tenants.value, { validated: false });

      // if no tenants need validation, move to the next step
      if (!tenantActionNeeded.value) {
        props.next();
      }
    }
  }, 10000);
};

const validateTenant = async (tenant: Tenant) => {
  validating.value = true;

  if (servicePrincipal.value?.id === undefined) {
    return;
  }

  const validationResult = await validateTenantAccess(servicePrincipal.value?.id, tenant.tenant_id, {});

  tenant.validated = validationResult.status === 'Validated';

  // If there are no more tenants that need validation, move to the next step
  if (!_.some(tenants.value, { validated: false })) {
    props.next();
  }

  validating.value = false;
};

const validateAll = () => {
  _.each(tenants.value, t => {
    validateTenant(t);
  });
};
</script>

<template>
  <div class="gatheringDetails">
    <div v-if="gatheringDetails" class="row">
      <div class="col">
        <h2>Gathering additional details about your Azure Environment</h2>
        <div class="pt-2">
          <BoxMessage type="info">
            <div class="spinner">
              <SpinnerLogo />
              <div class="text">
                Gathering additional information about your Azure Environment...
                <br />
                <small>This may take a few minutes.</small>
              </div>
            </div>
          </BoxMessage>
        </div>
      </div>
    </div>
    <div v-if="tenantActionNeeded">
      <AzureTenantFilterList
        :validating="validating"
        :validate-tenant="validateTenant"
        :validate-all="validateAll"
        :tenants="tenants"
      />
    </div>
  </div>
</template>

<style lang="scss">
.onboardingCodeSnippet .codePreview pre {
  height: auto;
}
div.validationStepperItemWrapper {
  cursor: pointer;
}

.spinner {
  padding-bottom: 3rem;
  font-weight: 200;
}
</style>
